import React from "react"
import { graphql } from "gatsby"

import { Col } from "../components/utilities"
import { Banner, Section, SectionGrid, Signature } from "../components/elements"
import { Layout, FullImage } from "../components/layouts/Layout"

const AboutPage = ({ data }) => {
  const { salon } = data
  return (
    <Layout title="About">
      <Banner title="about us" />
      <Section>
        <SectionGrid>
          <Col>
            <h3>Who We Are</h3>
            <p>
              KN Beauty Co. is a salon located in Rossford, OH that provides all
              services, including: Hair, Nails, Skin, Lashes, and Massage. Every
              professional is their own independent boss that focuses on modern,
              effortless techniques to achieve your goals. We strive to make
              every guest feel comfortable and welcomed when arriving. <br />
              <br />
              KN Beauty Co. is passionate about continuing to improve and evolve
              through education, products, and services. We are a close team
              that helps one another. Our mission is to make you feel your very
              best throughout your entire experience at our salon. Come check us
              out, we’d love to have you!
            </p>
            <br />
            <Signature />
          </Col>
          <Col>
            <FullImage picture={salon?.childImageSharp?.fluid?.src} />
          </Col>
        </SectionGrid>
      </Section>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query AboutQuery {
    salon: file(relativePath: { eq: "building-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          src
        }
      }
    }
  }
`
